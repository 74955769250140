import PropTypes, { useEffect } from "prop-types"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import styles from "./header.module.scss"
import { v4 as uuidv4 } from 'uuid';

import useViewport from "../useViewport"
import MobileMenu from "../mobileMenu/mobileMenu";

const Header = ({ siteTitle, title }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hades-media-logo.svg" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed
          }
        }
        extension
        publicURL
        base
      }
    }
  `)

  const menuItems = [
    {
      "title": "About",
      "url": "#about"
    },
    {
      "title" : "Experience",
      "url": "#experience"
    },
    {
      "title" : "Services",
      "url": "#services"
    },
    {
      "title" : "Workflow",
      "url": "#workflow"
    },
    {
      "title" : "Case studies",
      "url": "#case-studies"
    },
    {
      "title" : "Contact",
      "url": "#contact"
    }
  ]

  const { width } = useViewport()

  return (
    <>
      <header className={`${styles.mainHeader}`}>
        <div className={`${styles.mainHeaderWrapper} container container-wrapper`}>
          {!data.image.childImageSharp && data.image.extension === 'svg' && (
            title === 'Home' ? (
              <img src={data.image.publicURL} alt={data.image.base.split(".")[0]} />
            ) : (
              <Link to="/">
                <img src={data.image.publicURL} alt={data.image.base.split(".")[0]} />
              </Link>
            )
          ) }
          {(title === 'Home') && (width > 769) && (
            <nav>
              {menuItems.map(item => <button key={uuidv4()} onClick={() => scrollTo(item.url)}>{item.title}</button>)}
            </nav>
          )}
        </div>
      </header>
      {(title === 'Home') && (width <= 769) && <MobileMenu items={menuItems} />}
    </>
  )
}

export default Header
