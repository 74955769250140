import React from 'react'

const SocialMediaIcon = ({children, url, classes}) => {
  return (
    <>
      {(url && children) && (
        <div className={classes}>
          <a href={url} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        </div>
      )}
    </>
  )
}

export default SocialMediaIcon
