import React, { useRef, useEffect } from 'react'
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll'
import styles from "./contactSidebar.module.scss"
import { companyContacts } from "../../../content/main-content.json";
import SocialMediaIcon from "./socialMediaIcon"
import FacebookIcon from "../../images/socialMediaIcons/facebook-icon.inline.svg"
import TwitterIcon from "../../images/socialMediaIcons/twitter-icon.inline.svg"
import LinkedinIcon from "../../images/socialMediaIcons/linkedin-icon.inline.svg"
import ArrowIcon from "../../images/arrow.inline.svg"

const ContactSidebar = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const sideBar = useRef(null);

  useEffect(() => {
    const socialMediaIcons = sideBar.current.children[0].querySelectorAll('.social-media-icon');
    const websiteTitle = sideBar.current.children[0].querySelector('.site-title');
    const sidebarArrow = sideBar.current.children[0].querySelector('#sidebar-arrow');

    gsap.registerPlugin(ScrollTrigger, TimelineLite);
    
    let tl = new TimelineLite ({
      scrollTrigger: {
        toggleActions: "play none none reverse",
        toggleClass: { targets: sidebarArrow, className: 'active' },
      }
    });

    tl.from(socialMediaIcons, .75, { opacity: 0, x: -40, stagger: .2, autoAlpha: 0 })
      .from(websiteTitle, .75, { opacity: 0, y: -20, autoAlpha: 0 }, "-=.5")
      .to(sidebarArrow, .5, { rotate: 180 }, "-=1");

  })

  return (
    <aside 
    ref={sideBar} 
    className={styles.contactSidebar}>
      <div className={styles.sectionWrapper}>
        <div className={styles.content}>
          <SocialMediaIcon url={companyContacts.socialMedia.facebook.url} classes={`${styles.socialMediaIcon} social-media-icon`}>
            <FacebookIcon />
          </SocialMediaIcon>
          <SocialMediaIcon url={companyContacts.socialMedia.twitter.url} classes={`${styles.socialMediaIcon} social-media-icon`}>
            <TwitterIcon />
          </SocialMediaIcon>
          <SocialMediaIcon url={companyContacts.socialMedia.linkedin.url} classes={`${styles.socialMediaIcon} social-media-icon`}>
            <LinkedinIcon />
          </SocialMediaIcon>
        </div>
        {site.siteMetadata.title &&
          <div className={styles.titleWrapper}>
            <h4 className={`${styles.siteTitle} site-title`}>{site.siteMetadata.title}</h4>
          </div>
        }
        <button id="sidebar-arrow" className={styles.arrow} onClick={() => scrollTo('#content')}><ArrowIcon /></button>
      </div>
    </aside>
  )
}

export default ContactSidebar
