import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Logo = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hades-media-logo.svg" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed
          }
        }
        extension
        publicURL
        base
      }
    }
  `)

  return (
    <>
      {!data.image.childImageSharp && data.image.extension === 'svg' ? (
        <div>
          <img src={data.image.publicURL} alt={data.image.base.split(".")[0]} />
        </div>
      ) : (
        <h3>{siteTitle}</h3>
      )}
    </>
  )
}

export default Logo
