import React, { useState } from "react"
import styles from "./mobileMenu.module.scss"
import { v4 as uuidv4 } from 'uuid';

const MobileMenu = ({items}) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={`${styles.mobileMenuWrapper} ${open ? styles.open : styles.close} ${styles.mobileMenu}`}>
        <div className={`${styles.closeIcon}`} onClick={() => toggleMenu()}>
          <div className={styles.iconWrapper}>
            <div className={`${styles.closeIconLine}`}></div>
            <div className={`${styles.closeIconLine}`}></div>
          </div>
        </div>
        <div className={`container container-wrapper`}>
          <nav className={`${styles.mobileMenu}`} onClick={() => toggleMenu()}>
            {items.map((item) => <a href={item.url} key={uuidv4()}>{item.title}</a>)}
          </nav>
        </div>
      </div>
      <div className={`${styles.menuIcon}`} onClick={toggleMenu}>
        <div className={styles.iconWrapper}>
          <div className={styles.menuIconLine}></div>
          <div className={styles.menuIconLine}></div>
          <div className={styles.menuIconLine}></div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
