/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer/footer"
import ContactSidebar from "./contactSidebar/contactSidebar"
import "../scss/App.scss"

const Layout = ({ children, title }) => {
  return (
    <div id="content">
      <Header title={title} />
      <ContactSidebar />
      <main>{children}</main>
      <Footer title={title} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
