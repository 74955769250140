import React from "react"
import { v4 as uuidv4 } from 'uuid';
import Logo from "../logo"
import styles from "./footer.module.scss"
import { companyContacts } from "../../../content/main-content.json";

const Footer = ({title}) => {
  console.log(title)
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerWrapper}>
          <Logo />
          {companyContacts &&
            <div className={styles.footerSectionsWrapper}>

              {companyContacts.address && 
                <div className={styles.footerSection}>
                  <p>{companyContacts.address}</p>
                </div>
              }

              {(companyContacts.phoneNumber || companyContacts.emailAddress) &&
                <div className={styles.footerSection}>
                  <ul>

                    {companyContacts.emailAddress && 
                      <li>
                        <a href={`mailto:${companyContacts.emailAddress}`}>{companyContacts.emailAddress}</a>
                      </li>
                    }

                    {companyContacts.phoneNumber && 
                      <li>
                        <a href={`tel:${companyContacts.phoneNumber}`}>{companyContacts.phoneNumber}</a>
                      </li>
                    }

                    {title !== 'Become A Partner' && (
                      <li><a href="/become-a-partner" target="_blank">Referral program</a></li>
                    )}

                  </ul>
                </div>
              }

              {companyContacts.socialMedia && Object.values(companyContacts.socialMedia).length > 0 && 
                <div className={styles.footerSection}>
                  <ul>
                    {Object.values(companyContacts.socialMedia).map(item => (
                      <li key={uuidv4()}><a href={item.url} target="_blank" rel="noreferrer noopener">{item.title}</a></li>
                    ))}
                  </ul>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </footer>
  )
}

export default Footer
