import React, { useEffect, useState } from 'react'

const useViewport = () => {
  let windowWidth;

  if (typeof window !== `undefined`) {
    windowWidth = window.innerWidth
  }

  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}

export default useViewport